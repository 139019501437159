import React, { Component } from 'react';
import Form, { TextInput, FileInput, SelectInput } from '../Form'
import { server, authorization } from '../config'

class ProductCategoryForm extends Component {
  constructor(props) {
    super(props)
    this.state = { data: { details: {} }, categories: [] }
    this.handleSubmit = this.handleSubmit.bind(this)
    if (props.id) this.updateData()
    this.getCategories()
  }
  async getCategories() {
    const resp = await fetch(`${server}/product/categories`, {
      headers: { authorization },
    })
    const categories = await resp.json()
    this.setState({ categories })
  }
  renderCategoriesOptions(categories = this.state.categories, prefix = '') {
    return categories
      .filter(({ id }) => id !== this.props.id)
      .map(({ id, name, categories }) => [
        <option value={id} key={id}>{prefix}{name}</option>,
        ...this.renderCategoriesOptions(categories, `${prefix}- `)
      ]).flat()
  }
  async updateData() {
    const { id } = this.props
    const resp = await fetch(`${server}/product/category/${id}`, {
      headers: { 'Content-type': 'application/json', authorization },
    })
    this.setState({ data: await resp.json() })
  }
  async handleSubmit({ name, parentId, image }) {
    const { id } = this.props
    const resp = await fetch(`${server}/product/category/${id || ''}`, {
      method: id ? 'put' : 'post',
      headers: { 'Content-type': 'application/json', authorization },
      body: JSON.stringify({ name, parentId, details: { image } }),
    })
    if (resp.ok) this.props.onClose()
  }
  render() {
    const { data } = this.state
    return (
      <div className="ProductCategoryForm">
        <Form onSubmit={this.handleSubmit}>
          <TextInput label="Nazwa" name="name" value={data.name} />
          <SelectInput label="Kategoria nadrzędna" name="parentId" value={data.parentId || ''}>
            <option value="">-- Brak --</option>
            {this.renderCategoriesOptions()}
          </SelectInput>
          <FileInput label="Obrazek" name="image" value={data.details.image} />
        </Form>
      </div>
    );
  }
}

export default ProductCategoryForm;
