import React, { Component } from 'react';
import Form, { TextInput, FileInput } from '../Form'
import { server, authorization } from '../config'

class CompanyCategoryForm extends Component {
  constructor(props) {
    super(props)
    this.state = { data: { details: {} } }
    this.handleSubmit = this.handleSubmit.bind(this)
    if (props.id) this.updateData()
  }
  async updateData() {
    const { id } = this.props
    const resp = await fetch(`${server}/company/category/${id}`, {
      headers: { 'Content-type': 'application/json', authorization },
    })
    this.setState({ data: await resp.json() })
  }
  async handleSubmit({ name, image }) {
    const { id } = this.props
    const resp = await fetch(`${server}/company/category/${id || ''}`, {
      method: id ? 'put' : 'post',
      headers: { 'Content-type': 'application/json', authorization },
      body: JSON.stringify({ name, details: { image } }),
    })
    if (resp.ok) this.props.onClose()
  }
  render() {
    const { data } = this.state
    const disabled = data.name === 'Wizytownik'
    return (
      <div className="CompanyCategoryForm">
        <Form onSubmit={this.handleSubmit}>
          <TextInput label="Nazwa" name="name" value={data.name} disabled={disabled} />
          <FileInput label="Obrazek" name="image" value={data.details.image} />
        </Form>
      </div>
    );
  }
}

export default CompanyCategoryForm;
