import React, { Component } from 'react';
import ArticlesList from '../ArticlesList'
import ArticleForm from '../ArticleForm'
import ArticleParticipants from '../ArticleParticipants'

const title = {
  events: 'Wydarzenia',
  news: 'Projekty',
  sales: 'Okazje',
  updates: 'Nowości',
  videos: 'Artykuły Wideo',
  movies: 'Wideo',
}

class ArticlesPage extends Component {
  constructor(props) {
    super(props)
    this.state = { page: 'list', query: {} }
    this.handleClose = this.handleClose.bind(this)
    this.handleEditArticle = this.handleEditArticle.bind(this)
    this.handleShowParticipants = this.handleShowParticipants.bind(this)
  }
  changePage(page, query = {}) {
    this.setState({ page, query })
  }
  handleClose() {
    this.changePage('list')
  }
  handleEditArticle(id) {
    this.changePage('edit', { id })
  }
  handleShowParticipants(article) {
    this.changePage('participants', article)
  }
  render() {
    const { category } = this.props
    const { page, query } = this.state
    return (
      <div className="ArticlesPage">
        <h1>{query.trash && 'Archiwalne '}{title[category]}</h1>
        <button onClick={() => this.changePage('new')}>Nowy artykuł</button>
        {page === 'list' && !query.trash && <button onClick={() => this.changePage('list', { trash: true })}>Archiwum</button>}
        {page === 'list' && query.trash && <button onClick={() => this.changePage('list')}>Opublikowane</button>}
        {page === 'list' && <ArticlesList category={category} {...query} onEditArticle={this.handleEditArticle} onShowParticipants={this.handleShowParticipants} />}
        {page === 'new' && <ArticleForm category={category} onClose={this.handleClose} />}
        {page === 'edit' && <ArticleForm category={category} {...query} onClose={this.handleClose} />}
        {page === 'participants' && <ArticleParticipants {...query} />}
      </div>
    );
  }
}

export default ArticlesPage;
