import React, { Component } from 'react';
import { server, authorization } from '../config'
import './ProductCategoriesList.css'

class ProductCategoriesList extends Component {
  constructor(props) {
    super(props)
    this.state = { categories: [] }
  }
  componentDidMount() {
    this.loadCategories()
  }
  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      this.loadCategories()
    }
  }
  async loadCategories() {
    this.setState({ categories: [] })
    const url = `${server}/product/categories`
    const resp = await fetch(url, { headers: { authorization } })
    this.setState({ categories: await resp.json() })
  }
  async deleteCategory(id) {
    if (!window.confirm('Czy jesteś pewien?')) return
    const resp = await fetch(`${server}/product/category/${id}`, {
      method: 'delete',
      headers: { 'Content-type': 'application/json', authorization },
    })
    if (resp.ok) await this.loadCategories()
  }
  async handleDelete(event, productId) {
    event.stopPropagation()
    await this.deleteCategory(productId)
  }
  editCategory(id) {
    this.props.onEdit(id)
  }
  renderCategories(categories) {
    return <ul>
      {categories.map(({ id, name, categories }) => <li key={name}>
        <main onClick={() => this.editCategory(id)}>
          <span className="title">{name}</span>
          <div className="actions">
            <button onClick={(event) => this.handleDelete(event, id)}>Usuń</button>
          </div>
        </main>
        {this.renderCategories(categories)}
      </li>)}
    </ul>
  }
  render() {
    const { categories } = this.state
    return (
      <div className="ProductCategoriesList">
        {this.renderCategories(categories)}
      </div>
    );
  }
}

export default ProductCategoriesList;
