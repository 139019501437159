import React, { Component } from 'react';
import PagesSelect from '../PagesSelect'
import { server, authorization } from '../config'
import './PagesList.css'

class PagesList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      items: [],
    }
    this.addNewItem = this.addNewItem.bind(this)
    this.getItems()
  }
  async getItems() {
    const resp = await fetch(`${server}/static/${this.props.type}`)
    let items = []
    try {
      if (resp.status === 200) {
        items = await resp.json()
      }
    } catch (error) {
      // NOP
    }
    this.setState({ items })
  }
  async updateItems(items) {
    this.setState({ items })
    await fetch(`${server}/static/${this.props.type}`, {
      method: 'put',
      headers: { 'Content-type': 'application/json', authorization },
      body: JSON.stringify(items),
    })
  }
  async addNewItem(value) {
    this.updateItems([...this.state.items, value])
  }
  move(index, move) {
    const items = [...this.state.items]
    items.splice(index, 1)
    items.splice(index + move, 0, this.state.items[index])
    this.updateItems(items)
  }
  delete(index) {
    const items = [...this.state.items]
    items.splice(index, 1)
    this.updateItems(items)
  }
  change(index, value) {
    const items = [...this.state.items]
    items[index] = value
    this.updateItems(items)
  }
  render() {
    return (
      <div className="PagesList">
        <h1>{this.props.title}</h1>
        <ol>{this.state.items.map(((value, index) =>
          <li key={value}>
            <PagesSelect value={value} options={this.props.options} onChange={(value) => this.change(index, value)} />
            <button onClick={() => this.move(index, -1)}>▲</button>
            <button onClick={() => this.move(index, 1)}>▼</button>
            <button onClick={() => this.delete(index)}>Usuń</button>
          </li>
        ))}</ol>
        <div className="footer">
          <PagesSelect label="Dodaj " options={this.props.options} onChange={this.addNewItem} />
        </div>
      </div>
    );
  }
}

export default PagesList;
