import React, { Component } from "react";

class FormTextareaInput extends Component {
  constructor(props) {
    super(props);
    this.state = { value: props.value };
    this.handleChange = this.handleChange.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      value: nextProps.value
    });
  }
  getValue() {
    return this.state.value;
  }
  handleChange(event) {
    const { onChange } = this.props;
    const { value } = event.target;
    this.setState({ value });
    if (typeof onChange === "function") {
      onChange(value);
    }
  }
  render() {
    const { label, name, description, collapsable } = this.props;
    const { value } = this.state;

    const header = <span>{label}</span>;
    const details = !!description ? <p>{description}</p> : null;
    const content = (
      <textarea value={value} name={name} onChange={this.handleChange} />
    );

    if (collapsable) {
      return (
        <label>
          <details>
            <summary>{header}</summary>
            {details}
            {content}
          </details>
        </label>
      );
    }

    return (
      <label>
        {header}
        {details}
        {content}
      </label>
    );
  }
}

export default FormTextareaInput;
