import React, { Component } from 'react';

class FormCheckboxInput extends Component {
  constructor(props) {
    super(props)
    this.state = { value: props.value }
    this.handleChange = this.handleChange.bind(this)
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      value: nextProps.value,
    })
  }
  getValue() {
    return this.state.value
  }
  handleChange(event) {
    this.setState({
      value: event.target.checked,
    })
  }
  render() {
    const { label, name } = this.props
    const { value } = this.state

    return (<label>
      <span>{label}</span>
      <input type="checkbox" checked={value} name={name} onChange={this.handleChange} />
    </label>)
  }
}

export default FormCheckboxInput;
