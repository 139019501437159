import React, { Component } from 'react';
import moment from 'moment'
import classNames from 'classnames'
import 'moment/locale/pl'
import { server, authorization } from '../config'
import './ArticlesList.css'

moment.locale('pl')

class ArticlesList extends Component {
  constructor(props) {
    super(props)
    this.state = { articles: [] }
  }
  componentDidMount() {
    this.loadArticles()
  }
  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      this.loadArticles()
    }
  }
  async loadArticles() {
    const { category, trash } = this.props
    this.setState({ articles: [] })
    const url = `${server}/articles/${category}?trash=${trash}`
    const resp = await fetch(url, { headers: { authorization } })
    this.setState({ articles: await resp.json() })
  }
  parseDate(date) {
    const time = moment(date)
    const now = moment(new Date())
    if (moment.duration(now.diff(time)).asHours() < 20) return time.fromNow()
    return time.format('DD MMMM YYYY r.')
  }
  async deleteArticle(id) {
    const resp = await fetch(`${server}/article/${id}`, {
      method: 'delete',
      headers: { 'Content-type': 'application/json', authorization },
      body: JSON.stringify({
        permanent: !!this.props.trash,
      }),
    })
    if (resp.ok) await this.loadArticles()
  }
  async handleDelete(event, articleId) {
    event.stopPropagation()
    await this.deleteArticle(articleId)
  }
  editArticle(id) {
    this.props.onEditArticle(id)
  }
  showParticipants(event, article) {
    event.stopPropagation()
    this.props.onShowParticipants(article)
  }
  render() {
    const { category, trash } = this.props
    const { articles } = this.state
    return (
      <div className="ArticlesList">
        <ul>
          {articles.map(article => <li
            key={article.id}
            onClick={() => this.editArticle(article.id)}
            className={classNames({ 'not-published': moment(article.publishedAt).isAfter(moment()) })}
          >
            <span className="title">{article.meta.internalTitle || article.meta.title}</span>
            <span className="since">{this.parseDate(article.createdAt)}</span>
            <div className="actions">
              {category === 'events' ? <button onClick={(e) => this.showParticipants(e, article)}>Uczestnicy</button> : null}
              <button onClick={(e) => this.handleDelete(e, article.id)}>{trash ? 'Usuń' : 'Przenieś do archiwum'}</button>
            </div>
          </li>)}
        </ul>
      </div>
    );
  }
}

export default ArticlesList;
