import React, { Component, createRef, Fragment } from 'react'
import Form, { TextInput, MultiSelectInput, DatalistInput, SelectInput } from '../Form'
import PagesSelect from '../PagesSelect'
import { server, authorization } from '../config'
import './MessagePage.css'

const provinces = [
  'Dolnośląskie',
  'Kujawsko-Pomorskie',
  'Lubelskie',
  'Lubuskie',
  'Łódzkie',
  'Małopolskie',
  'Mazowieckie',
  'Opolskie',
  'Podkarpackie',
  'Podlaskie',
  'Pomorskie',
  'Śląskie',
  'Świętokrzyskie',
  'Warmińsko-Mazurskie',
  'Wielkopolskie',
  'Zachodniopomorskie',
]

const provincesSelectOptions = provinces.map(province => ({ value: province, label: province }))

provincesSelectOptions.unshift({ value: '', label: 'Wszystkie' })

const defaultData = {
  eventParticipants: 'onlyParticipants',
}

class MessagePage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      result: '',
      data: defaultData,
      users: [],
      events: [],
    }
    this.formRef = createRef()
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleUserChange = this.handleUserChange.bind(this)
    this.handleEventChange = this.handleEventChange.bind(this)
  }
  componentDidMount() {
    this.loadUsers()
    this.loadEvents()
  }
  findUserId(email) {
    if (!email) return null
    const { users } = this.state;
    const user = users.find(user => user.email === email)
    return user ? user.id : null
  }
  async handleSubmit(data) {
    this.setState({ data })
    if (!data.title) return
    const user = this.findUserId(data.user)
    if (data.user && !user) return
    const resp = await fetch(`${server}/message`, {
      method: 'post',
      headers: { 'Content-type': 'application/json', authorization },
      body: JSON.stringify({
        ...data,
        user,
      }),
    })
    this.setState({
      result: resp.ok ? 'ok' : 'fail',
      data,
    })
  }
  async loadUsers() {
    const resp = await fetch(`${server}/users`, { headers: { authorization } })
    this.setState({ users: await resp.json() })
  }
  async loadEvents() {
    const resp = await fetch(`${server}/articles/events`, { headers: { authorization } })
    this.setState({ events: await resp.json() })
  }
  getData() {
    const data = { ...defaultData, ...this.state.data }
    const formData = this.formRef.current.getData()
    Object.entries(formData).forEach(([key, value]) => {
      if (value != null) {
        data[key] = value
      }
    })
    return data
  }
  handleUserChange(user) {
    this.setState({ data: { ...this.getData(), user } })
  }
  handleEventChange(event) {
    this.setState({ data: { ...this.getData(), event } })
  }
  render() {
    const { result, data, users, events } = this.state
    const { user, event } = data
    return (
      <div className="MessagePage">
        <h1>Nowe powiadomienie</h1>
        {result === 'ok' ? <span className="result ok">Powiadomienia zostały wysłane</span> : null}
        {result === 'fail' ? <span className="result fail">Wystąpiły błędy przy próbie wysłania powiadomień</span> : null}
        <Form onSubmit={this.handleSubmit} ref={this.formRef}>
          <TextInput label="Tytuł" name="title" value={data.title} />
          <TextInput label="Treść (opcjonalnie)" name="body" value={data.body} />
          <PagesSelect label="Przekierowanie" name="redirect" value={data.redirect} includeHomePage includeMainPages />
          <DatalistInput label="Użytkownik" name="user" value={data.user} onChange={this.handleUserChange}>
            <option value={null}></option>
            {users
              .map(user => <option key={user.id}>{user.email}</option>)}
          </DatalistInput>
          {!user && <MultiSelectInput label="Województwa" name="provinces" options={provincesSelectOptions} values={data.provinces} />}
          {!user && <SelectInput label="Wydarzenie" name="event" value={data.event} onChange={this.handleEventChange}>
            <option></option>
            {events.map(event => <option value={event.id} key={event.id}>{event.meta.internalTitle || event.meta.title}</option>)}
          </SelectInput>}
          {!user && event && <SelectInput label="Uczestnicy wydarzenie" name="eventParticipants" value={data.eventParticipants}>
            <option value="onlyParticipants">Tylko uczestnicy</option>
            <option value="notParticipants">Tylko niezapisani</option>
          </SelectInput>}
        </Form>
      </div>
    );
  }
}

export default MessagePage;
