import React, { Component } from 'react';
import UsersList from '../UsersList'
import UserForm from '../UserForm'

class UsersPage extends Component {
  constructor(props) {
    super(props)
    this.state = { page: 'list', query: {} }
    this.handleClose = this.handleClose.bind(this)
    this.handleEditUser = this.handleEditUser.bind(this)
  }
  changePage(page, query = {}) {
    this.setState({ page, query })
  }
  handleClose() {
    this.changePage('list')
  }
  handleEditUser(id) {
    this.changePage('edit', { id })
  }
  render() {
    const { page, query } = this.state
    return (
      <div className="UsersPage">
        <h1>Użytkownicy</h1>
        {page === 'list' && <UsersList {...query} onEditUser={this.handleEditUser} />}
        {page === 'edit' && <UserForm {...query} onClose={this.handleClose} />}
      </div>
    );
  }
}

export default UsersPage;
