import React, { Component } from 'react';
import { server, authorization } from '../config'
import './CompanyCategoriesList.css'

class CompanyCategoriesList extends Component {
  constructor(props) {
    super(props)
    this.state = { categories: [] }
  }
  componentDidMount() {
    this.loadCategories()
  }
  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      this.loadCategories()
    }
  }
  async loadCategories() {
    this.setState({ categories: [] })
    const url = `${server}/company/categories`
    const resp = await fetch(url, { headers: { authorization } })
    this.setState({ categories: await resp.json() })
  }
  async deleteCategory(id) {
    if (!window.confirm('Czy jesteś pewien?')) return
    const resp = await fetch(`${server}/company/category/${id}`, {
      method: 'delete',
      headers: { 'Content-type': 'application/json', authorization },
    })
    if (resp.ok) await this.loadCategories()
  }
  async handleDelete(event, companyId) {
    event.stopPropagation()
    await this.deleteCategory(companyId)
  }
  editCategory(id) {
    this.props.onEdit(id)
  }
  render() {
    const { categories } = this.state
    return (
      <div className="CompanyCategoriesList">
        <ul>
          {categories.map(({ id, name }) => <li key={name}>
            <span onClick={() => this.editCategory(id)}>{name}</span>
            <div className="actions">
              {name !== 'Wizytownik' && <button onClick={(event) => this.handleDelete(event, id)}>Usuń</button>}
            </div>
          </li>)}
        </ul>
      </div>
    );
  }
}

export default CompanyCategoriesList;
