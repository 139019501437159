import React, { Component } from 'react';
import moment from 'moment'
import 'moment/locale/pl'
import { server, authorization } from '../config'
import './CompaniesList.css'

moment.locale('pl')

class CompaniesList extends Component {
  constructor(props) {
    super(props)
    this.state = { categories: [] }
  }
  componentDidMount() {
    this.loadCompanies()
  }
  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      this.loadCompanies()
    }
  }
  async loadCompanies() {
    const { trash } = this.props
    this.setState({ categories: [] })
    const url = `${server}/companies?trash=${trash}&orphans=true`
    const resp = await fetch(url, { headers: { authorization } })
    this.setState({ categories: await resp.json() })
  }
  parseDate(date) {
    const time = moment(date)
    const now = moment(new Date())
    if (moment.duration(now.diff(time)).asHours() < 20) return time.fromNow()
    return time.format('DD MMMM YYYY r.')
  }
  async deleteCompany(id) {
    const { trash } = this.props
    if (trash) {
      if (!window.confirm('Czy jesteś pewien?')) return
    }
    const resp = await fetch(`${server}/company/${id}`, {
      method: 'delete',
      headers: { 'Content-type': 'application/json', authorization },
      body: JSON.stringify({
        permanent: !!this.props.trash,
      }),
    })
    if (resp.ok) await this.loadCompanies()
  }
  async restoreCompany(id) {
    const resp = await fetch(`${server}/company/${id}/restore`, {
      method: 'put',
      headers: { authorization },
    })
    if (resp.ok) await this.loadCompanies()
  }
  async handleDelete(event, companyId) {
    event.stopPropagation()
    await this.deleteCompany(companyId)
  }
  async handleRestore(event, companyId) {
    event.stopPropagation()
    await this.restoreCompany(companyId)
  }
  async showCompanyProducts(event, companyId) {
    event.stopPropagation()
    this.props.onShowCompanyProducts(companyId)
  }
  async showCompanyPdfs(event, companyId) {
    event.stopPropagation()
    this.props.onShowCompanyPdfs(companyId)
  }
  editCompany(id) {
    this.props.onEditCompany(id)
  }
  render() {
    const { trash } = this.props
    const { categories } = this.state
    return (
      <div className="CompaniesList">
        <ul className="categories">
          {categories.map(({ category, categoryDetails, companies }) => <li key={category}>
            <span className="category">{category}</span>
            <ul className="companies">
              <li className="head">
                <span className="title">Nazwa</span>
                <span className="since">Data utworzenia</span>
                <span className="metric">Licznik kliknięć</span>
                <div className="actions">Akcje</div>
              </li>
            </ul>
            <ul className="companies">
              {companies.map(company => <li key={company.id} onClick={() => this.editCompany(company.id)}>
              <span className="title">{company.meta.title}</span>
              <span className="since">{this.parseDate(company.createdAt)}</span>
              <span className="metric">{company.clicksCount}</span>
              <div className="actions">
                <button onClick={(event) => this.showCompanyProducts(event, company.id)}>Produkty</button>
                <button onClick={(event) => this.showCompanyPdfs(event, company.id)}>Katalogi</button>
                {trash && <button onClick={(event) => this.handleRestore(event, company.id)}>Opublikuj</button>}
                <button onClick={(event) => this.handleDelete(event, company.id)}>{trash ? 'Usuń' : 'Ukryj'}</button>
              </div>
              </li>)}
            </ul>
          </li>)}
        </ul>
      </div>
    );
  }
}

export default CompaniesList;
