import React, { Component } from 'react';

class FormMultiSelectInput extends Component {
  constructor(props) {
    super(props)
    this.state = { values: props.values || [] }
    this.handleChange = this.handleChange.bind(this)
  }
  componentWillReceiveProps(nextProps) {
    this.setState({ values: nextProps.values || [] })
  }
  getValue() {
    return this.state.values
  }
  handleChange(event) {
    const values = Array.from(event.target.selectedOptions).map(option => option.value)
    this.setState({ values })
  }
  render() {
    const { values } = this.state
    const { label, name, options } = this.props
    return (<label>
      <span>{label}</span>
      <select name={name} value={values} onChange={this.handleChange} multiple>
        {options.map(({ value, label }, i) => <option
          key={i}
          value={value}
        >{label}</option>)}
      </select>
    </label>)
  }
}

export default FormMultiSelectInput;
