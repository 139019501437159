import React, { Component } from 'react';
import CompaniesList from '../CompaniesList'
import CompanyForm from '../CompanyForm'
import CompanyProducts from '../CompanyProducts'
import CompanyPdfs from '../CompanyPdfs'

class CompaniesPage extends Component {
  constructor(props) {
    super(props)
    this.state = { page: 'list', query: {} }
    this.handleClose = this.handleClose.bind(this)
    this.handleEditCompany = this.handleEditCompany.bind(this)
    this.handleShowCompanyProducts = this.handleShowCompanyProducts.bind(this)
    this.handleShowCompanyPdfs = this.handleShowCompanyPdfs.bind(this)
  }
  changePage(page, query = {}) {
    this.setState({ page, query })
  }
  handleClose() {
    this.changePage('list')
  }
  handleEditCompany(id) {
    this.changePage('edit', { id })
  }
  handleShowCompanyProducts(id) {
    this.changePage('products', { id })
  }
  handleShowCompanyPdfs(id) {
    this.changePage('pdfs', { id })
  }
  renderTitle() {
    const { page, query } = this.state
    if (page === 'products') return 'Produkty'
    if (page === 'pdfs') return 'Katalogi'
    return `${query.trash ? 'Ukryte ' : ''}Firmy`
  }
  render() {
    const { page, query } = this.state
    return (
      <div className="CompaniesPage">
        <h1>{this.renderTitle()}</h1>
        {page !== 'products' && page !== 'pdfs' && <button onClick={() => this.changePage('new')}>Nowa firma</button>}
        {page === 'list' && !query.trash && <button onClick={() => this.changePage('list', { trash: true })}>Ukryte</button>}
        {page === 'list' && query.trash && <button onClick={() => this.changePage('list')}>Widoczne</button>}
        {page === 'list' &&
          <CompaniesList
            {...query}
            onEditCompany={this.handleEditCompany}
            onShowCompanyProducts={this.handleShowCompanyProducts}
            onShowCompanyPdfs={this.handleShowCompanyPdfs}
          />
        }
        {page === 'new' && <CompanyForm onClose={this.handleClose} />}
        {page === 'edit' && <CompanyForm {...query} onClose={this.handleClose} />}
        {page === 'products' && <CompanyProducts {...query} />}
        {page === 'pdfs' && <CompanyPdfs {...query} />}
      </div>
    );
  }
}

export default CompaniesPage;
