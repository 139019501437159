import React, { Component } from "react";
import Form, { TextInput, FileInput } from "../Form";
import { server, authorization } from "../config";
import "./CompanyPdfs.css";

class CompanyPdfs extends Component {
  constructor(props) {
    super(props);
    this.state = { pdfs: [], pendingPdfs: [] };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount() {
    this.loadPdfs();
  }
  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      this.loadPdfs();
    }
  }
  async loadPdfs() {
    const { id } = this.props;
    this.setState({ pdfs: [] });
    const url = `${server}/company/${id}/pdfs`;
    const resp = await fetch(url, { headers: { authorization } });
    this.setState({ pdfs: await resp.json() });
  }
  async handleSubmit({ keywords, file, image, order }, pdfId) {
    const { id } = this.props;
    keywords = String(keywords)
      .split(",")
      .map(str => str.trim());
    const pdf = { details: { keywords, file, image, order } };
    this.setState({
      pendingPdfs: [
        ...this.state.pendingPdfs.filter(
          ({ details }) => details.keywords !== keywords
        ),
        pdf
      ]
    });
    const resp = await fetch(`${server}/company/${id}/pdf/${pdfId || ""}`, {
      method: pdfId ? "put" : "post",
      headers: { "Content-type": "application/json", authorization },
      body: JSON.stringify(pdf)
    });
    if (resp.ok) {
      await this.loadPdfs();
      this.setState({
        pendingPdfs: this.state.pendingPdfs.filter(a => a !== pdf)
      });
    } else {
      pdf.error = "Wystąpił błąd";
      this.setState({ pendingPdfs: [...this.state.pendingPdfs] });
    }
  }
  async deletePdf(pdfId) {
    if (!window.confirm("Czy jesteś pewien?")) return;
    const { id } = this.props;
    const resp = await fetch(`${server}/company/${id}/pdf/${pdfId}`, {
      method: "delete",
      headers: { authorization }
    });
    if (resp.ok) await this.loadPdfs();
  }
  async handleDelete(event, pdfId) {
    event.stopPropagation();
    await this.deletePdf(pdfId);
  }
  renderForm(data) {
    let { keywords, order } = data.details;
    keywords = Array.isArray(keywords) ? keywords.join() : "";

    return (
      <Form
        onSubmit={newData =>
          this.handleSubmit({ ...data.details, ...newData }, data.id)
        }
      >
        {data.id != null ? <TextInput label="ID" name="id" value={data.id} disabled /> : null}
        <TextInput label="Słowa kluczowe" name="keywords" value={keywords} />
        <TextInput label="Kolejność" type="number" name="order" value={order} />
        <FileInput
          label="PDF"
          name="file"
          value={data.details.file}
          noPreview
          accept=".pdf"
        />
        {data.clicksCount != null ? <TextInput label="Licznik kliknięć" name="id" value={data.clicksCount} disabled /> : null}
      </Form>
    );
  }
  render() {
    const { pdfs, pendingPdfs } = this.state;

    return (
      <div className="CompanyPdfs">
        <ul className="pdfs">
          {pdfs.map((data) => (
            <li key={data.id}>
              <div className="pdf">
                {data.details.image ? <img src={data.details.image.baseImage} /> : null}
                {this.renderForm(data)}
                <button onClick={event => this.handleDelete(event, data.id)}>
                  Usuń
                </button>
              </div>
            </li>
          ))}
        </ul>

        {pendingPdfs.length > 0 ? (
          <React.Fragment>
            <h3>Przetwarzane katalogi</h3>
            <ul className="pendingPdfs">
              {pendingPdfs.map(({ error }, index) => (
                <li key={index} className="pdf">
                  {error ? <p className="error">{error}</p> : null}
                </li>
              ))}
            </ul>
          </React.Fragment>
        ) : null}

        <h3>Dodaj katalog</h3>
        <div className="form">{this.renderForm({ details: {} })}</div>
      </div>
    );
  }
}

export default CompanyPdfs;
