import React, { Component } from 'react';

class FormSelectInput extends Component {
  constructor(props) {
    super(props)
    this.state = { value: props.value }
    this.handleChange = this.handleChange.bind(this)
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      value: nextProps.value,
    })
  }
  getValue() {
    return this.state.value
  }
  handleChange(event) {
    const { onChange } = this.props
    const { value } = event.target
    this.setState({ value })
    if (typeof onChange === 'function') {
      onChange(value)
    }
  }
  render() {
    const { label, name, children } = this.props
    const { value } = this.state

    return (<label>
      {label && <span>{label}</span>}
      <select value={value} name={name} onChange={this.handleChange}>
        {children}
      </select>
    </label>)
  }
}

export default FormSelectInput;
