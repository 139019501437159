import React, { Component } from 'react';

class FormFileInput extends Component {
  constructor(props) {
    super(props)
    this.state = { preview: '' }
    this.handleChange = this.handleChange.bind(this)
  }
  getValue() {
    return this.state.preview || this.props.value
  }
  async handleChange(event) {
    const [file] = event.target.files
    if (!file) {
      this.setState({ preview: '' })
      return
    }
    const reader = new FileReader()
    reader.addEventListener('load', () => {
      this.setState({ preview: reader.result })
    })
    reader.readAsDataURL(file)
  }
  render() {
    const { label, name, value, noPreview, accept } = this.props
    const { preview } = this.state
    const src = preview || (value && value['1'])
    return (<label>
      <span>{label}</span>
      {src && !noPreview ? <img src={src} alt="preview" /> : null}
      <input type="file" accept={accept || ".jpg, .jpeg, .png"} name={name} onChange={this.handleChange} />
    </label>)
  }
}

export default FormFileInput;
