import React, { Component } from 'react';
import PagesList from './PagesList'
import { server, authorization } from '../config'

class HomePage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      productOptions: [],
      pdfOptions: [],
    }
  }
  componentDidMount() {
    this.loadProductOptions()
    this.loadPdfOptions()
  }
  async loadProductOptions() {
    const resp = await fetch(`${server}/products`)
    const items = await resp.json()
    const options = []
    items.forEach(({ id, details }) => {
      options.push(<option value={id} key={id}>{id} - {details.name}</option>)
    })
    this.setState({ productOptions: options })
  }
  async loadPdfOptions() {
    const resp = await fetch(`${server}/pdfs`)
    const items = await resp.json()
    const options = []
    items.forEach(({ id, details: { keywords } }) => {
      options.push(<option value={id} key={id}>{id} - {(keywords || []).join(', ')}</option>)
    })
    this.setState({ pdfOptions: options })
  }
  render() {
    return (
      <>
        <PagesList title="Aktualności" type="banners" />
        <PagesList title="Produkty" type="last-products" options={this.state.productOptions} />
        <PagesList title="Katalogi" type="last-pdfs" options={this.state.pdfOptions} />
      </>
    );
  }
}

export default HomePage;
