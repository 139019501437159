import React, { Component } from "react";
import "./FormContest.css";

class FormContest extends Component {
  constructor(props) {
    super(props);
    this.state = { value: props.value };
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      value: nextProps.value
    });
  }
  getValue() {
    return this.state.value;
  }
  addNewQuestion = event => {
    event.preventDefault();
    this.setState(({ value }) => ({
      value: [
        ...(value || []),
        {
          question: "",
          answers: [],
          properAnswer: 0
        }
      ]
    }));
  };
  updateQuestion = (index, question) => {
    this.setState(({ value }) => {
      const newValue = [...value];
      newValue[index] = {
        ...value[index],
        question
      };
      return { value: newValue };
    });
  };
  addNewAnswer(event, index) {
    event.preventDefault();
    this.setState(({ value }) => {
      const newValue = [...value];
      newValue[index] = {
        ...value[index],
        answers: [...value[index].answers, ""]
      };
      return { value: newValue };
    });
  }
  updateAnswer(questionIndex, answerIndex, answer) {
    this.setState(({ value }) => {
      const newValue = [...value];
      const newAnswers = [...newValue[questionIndex].answers];
      newAnswers[answerIndex] = answer;
      newValue[questionIndex] = {
        ...value[questionIndex],
        answers: newAnswers
      };
      return { value: newValue };
    });
  }
  updateProperAnswer(questionIndex, answerIndex) {
    this.setState(({ value }) => {
      const newValue = [...value];
      newValue[questionIndex] = {
        ...value[questionIndex],
        properAnswer: answerIndex
      };
      return { value: newValue };
    });
  }
  deleteQuestion(event, questionIndex) {
    event.preventDefault();
    this.setState(({ value }) => {
      const newValue = [...value];
      newValue.splice(questionIndex, 1);
      return { value: newValue };
    });
  }
  deleteAnswer(event, questionIndex, answerIndex) {
    event.preventDefault();
    this.setState(({ value }) => {
      const newValue = [...value];
      const newAnswers = [...newValue[questionIndex].answers];
      newAnswers.splice(answerIndex, 1);
      newValue[questionIndex] = {
        ...newValue[questionIndex],
        answers: newAnswers
      };
      return { value: newValue };
    });
  }
  render() {
    const { name } = this.props;
    const { value } = this.state;

    const questions = value || [];

    return (
      <label>
        <details>
          <summary>Konkurs</summary>
          <table>
            <thead>
              <tr>
                <th />
                <th>Poprawna odpowiedź</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {questions.map(
                ({ question, answers, properAnswer }, questionIndex) => (
                  <React.Fragment key={questionIndex}>
                    <tr>
                      <td colSpan="2">
                        <input
                          key={questionIndex}
                          type="text"
                          placeholder="Pytanie"
                          value={question}
                          onChange={event =>
                            this.updateQuestion(
                              questionIndex,
                              event.target.value
                            )
                          }
                        />
                      </td>
                      <td className="cotest-actions-column">
                        <button
                          onClick={event =>
                            this.deleteQuestion(event, questionIndex)
                          }
                        >
                          Usuń
                        </button>
                      </td>
                    </tr>
                    {answers.map((answer, answerIndex) => (
                      <tr key={answerIndex}>
                        <td className="contest-answer">
                          <input
                            type="text"
                            placeholder="Odpowiedź"
                            value={answer}
                            onChange={event =>
                              this.updateAnswer(
                                questionIndex,
                                answerIndex,
                                event.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="radio"
                            checked={properAnswer === answerIndex}
                            onChange={() =>
                              this.updateProperAnswer(
                                questionIndex,
                                answerIndex
                              )
                            }
                          />
                        </td>
                        <td className="cotest-actions-column">
                          <button
                            onClick={event =>
                              this.deleteAnswer(
                                event,
                                questionIndex,
                                answerIndex
                              )
                            }
                          >
                            Usuń
                          </button>
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td>
                        <button
                          onClick={event =>
                            this.addNewAnswer(event, questionIndex)
                          }
                        >
                          Dodaj nową odpowiedź
                        </button>
                      </td>
                      <td />
                      <td />
                    </tr>
                  </React.Fragment>
                )
              )}
              <tr>
                <td colSpan="3">
                  <button onClick={this.addNewQuestion}>
                    Dodaj nowe pytanie
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </details>
      </label>
    );
  }
}

export default FormContest;
