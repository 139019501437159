import React, { Component } from 'react';
import Form, { TextInput, CheckboxInput } from '../Form'
import { server, authorization } from '../config'

class UserForm extends Component {
  constructor(props) {
    super(props)
    this.state = { data: {} }
    this.handleSubmit = this.handleSubmit.bind(this)
    if (props.id) this.updateData()
  }
  async updateData() {
    const { id } = this.props
    const resp = await fetch(`${server}/user/${id}`, {
      headers: { 'Content-type': 'application/json', authorization },
    })
    this.setState({ data: await resp.json() })
  }
  async handleSubmit({ admin }) {
    const { id } = this.props
    const resp = await fetch(`${server}/user/${id}`, {
      method: 'put',
      headers: { 'Content-type': 'application/json', authorization },
      body: JSON.stringify({ admin }),
    })
    if (resp.ok) this.props.onClose()
  }
  render() {
    const { data } = this.state

    if (!data.id) return null

    return (
      <div className="UserForm">
        <Form onSubmit={this.handleSubmit}>
          <TextInput label="Email" name="email" type="email" value={data.email} disabled />
          <CheckboxInput label="Administrator" name="admin" value={data.admin} />
        </Form>
      </div>
    );
  }
}

export default UserForm;
