import React, { Component } from 'react';
import Form, { TextInput, FileInput, MultiSelectInput, TexteditorInput } from '../Form'
import { server, authorization } from '../config'

class CompanyForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: { meta: {}, pdfs: {}, products: {} },
      categories: [],
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.getCategories()
    if (props.id) this.updateData()
  }
  async getCategories() {
    const resp = await fetch(`${server}/company/categories`, {
      headers: { 'Content-type': 'application/json', authorization },
    })
    const json = await resp.json()
    const categories = json
      .filter(({ name }) => name !== 'Wizytownik')
      .map(({ id, name }) => ({
        value: id,
        label: name,
      }))
    this.setState({ categories })
  }
  async updateData() {
    const { id } = this.props
    const resp = await fetch(`${server}/company/${id}`, {
      headers: { 'Content-type': 'application/json', authorization },
    })
    this.setState({ data: await resp.json() })
  }
  async handleSubmit({
    categories, title, image, transparentImage, description, content, keywords, pdfsImage, productsImage,
  }) {
    const { id } = this.props
    keywords = String(keywords).split(',').map(str => str.trim())
    const resp = await fetch(`${server}/company/${id || ''}`, {
      method: id ? 'put' : 'post',
      headers: { 'Content-type': 'application/json', authorization },
      body: JSON.stringify({
        meta: { title, image, transparentImage, description, keywords },
        pdfs: { image: pdfsImage },
        products: { image: productsImage },
        categories,
        content,
      }),
    })
    if (resp.ok) this.props.onClose()
  }
  render() {
    const { data, categories } = this.state
    let { keywords } = data.meta
    keywords = Array.isArray(keywords) ? keywords.join() : ''
    return (
      <div className="CompanyForm">
        <Form onSubmit={this.handleSubmit}>
          <MultiSelectInput label="Kategorie" name="categories" values={data.categories} options={categories} />
          <TextInput label="Tytuł" name="title" value={data.meta.title} />
          <FileInput label="Obrazek" name="image" value={data.meta.image} />
          <FileInput label="Obrazek z transparentnością" name="transparentImage" value={data.meta.transparentImage} />
          <FileInput label="Tło łącza do katalogów" name="pdfsImage" value={data.pdfs.image} />
          <FileInput label="Tło łącza do produktów" name="productsImage" value={data.products.image} />
          <TextInput label="Słowa kluczowe" name="keywords" value={keywords} />
          <TextInput label="Krótki opis" name="description" value={data.meta.description} />
          <TexteditorInput label="Treść" name="content" value={data.content} />
        </Form>
      </div>
    );
  }
}

export default CompanyForm;
