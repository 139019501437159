import React, { Component } from "react";
import * as SMSHelper from "smshelper";
import moment from "moment";
import Form, {
  TextInput,
  CheckboxInput,
  FileInput,
  TexteditorInput,
  TextareaInput,
  ContestForm
} from "../Form";
import { server, authorization } from "../config";
import "./ArticleForm.css";

const QR_CODE_LENGTH = 6;
const QR_CODE_URL_LENGTH = 30;

class ArticleForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: { meta: {} },
      smsStats: { length: 0, messagesAmount: 0 },
      error: null
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleParticipantNotifySmsChange = this.handleParticipantNotifySmsChange.bind(
      this
    );
    if (props.id) this.updateData();
  }
  handleParticipantNotifySmsChange(value) {
    let msg = String(value || "");
    msg = msg.replace(
      /\{QR_CODE\}/g,
      new Array(QR_CODE_LENGTH).fill("_").join("")
    );
    msg = msg.replace(
      /\{QR_CODE_URL\}/g,
      new Array(QR_CODE_URL_LENGTH).fill("_").join("")
    );
    const { length } = msg;
    const messagesAmount = SMSHelper.parts(msg);
    this.setState({
      data: {
        ...this.state.data,
        meta: {
          ...this.state.data.meta,
          participantNotifySms: value
        }
      },
      smsStats: { length, messagesAmount }
    });
  }
  async updateData() {
    const { id } = this.props;
    const resp = await fetch(`${server}/article/${id}`, {
      headers: { "Content-type": "application/json", authorization }
    });
    const data = await resp.json();
    this.setState({ data });
    this.handleParticipantNotifySmsChange(data.meta.participantNotifySms);
  }
  async handleSubmit({
    title,
    internalTitle,
    publishedAt,
    deleteAt,
    enableDeleteAt,
    eventDate,
    address,
    priority,
    backgroundColor,
    image,
    youtubeId,
    content,
    participate,
    participantJoinedEmail,
    wordpressFormId,
    participantNotifyEmail,
    participantNotifyEmailSubject,
    participantNotifySms,
    webinarId,
    youTubePremiere,
    youTubePremiereContent,
    isWebinar,
    participantWebinarNotifyEmailSubject,
    participantWebinarNotifyEmail,
    contest,
    additionalEventQuestion
  }) {
    const { id, category } = this.props;
    this.setState({ error: null });
    const resp = await fetch(`${server}/article/${id || ""}`, {
      method: id ? "put" : "post",
      headers: { "Content-type": "application/json", authorization },
      body: JSON.stringify({
        category,
        content,
        publishedAt,
        priority,
        meta: {
          title,
          internalTitle,
          backgroundColor,
          image,
          youtubeId,
          eventDate,
          eventDateUtc: moment(eventDate)
            .subtract(moment(eventDate).utcOffset(), "minutes")
            .toISOString(),
          deleteAt: enableDeleteAt ? deleteAt : undefined,
          address,
          participate,
          participantJoinedEmail,
          wordpressFormId,
          participantNotifyEmail,
          participantNotifyEmailSubject,
          participantNotifySms,
          webinarId,
          youTubePremiere,
          youTubePremiereContent,
          isWebinar,
          participantWebinarNotifyEmailSubject,
          participantWebinarNotifyEmail,
          contest,
          additionalEventQuestion
        }
      })
    });
    if (resp.ok) {
      this.props.onClose();
    } else {
      this.setState({
        error: await resp.text()
      });
    }
  }
  formatSmsMessagesAmount(n) {
    if (n === 0 || n > 1) return `${n} wiadomości SMS`;
    return `${n} wiadomość SMS`;
  }
  render() {
    const { category } = this.props;
    const { data, smsStats, error } = this.state;

    let { participate } = data.meta;
    if (participate == null) participate = true;

    return (
      <div className="ArticleForm">
        <Form onSubmit={this.handleSubmit}>
          <TextInput
            label="Tytuł w aplikacji"
            name="title"
            value={data.meta.title}
          />
          <TextInput
            label="Tytuł w panelu administracyjnym"
            name="internalTitle"
            value={data.meta.internalTitle}
          />
          <TextInput
            label="Data opublikowania"
            name="publishedAt"
            type="datetime-local"
            value={data.publishedAt}
          />
          {category === "events" && (
            <TextInput
              label="Data wydarzenia"
              name="eventDate"
              type="datetime"
              value={data.meta.eventDate}
            />
          )}
          <CheckboxInput
            label="Automatycznie przenieś do archiwum"
            name="enableDeleteAt"
            value={data.meta.deleteAt != null}
          />
          <TextInput
            label="Data przeniesienia do archiwum"
            name="deleteAt"
            type="datetime-local"
            value={data.meta.deleteAt}
          />
          {category === "events" && (
            <CheckboxInput
              label="Możliwość zapisania się"
              name="participate"
              value={participate}
            />
          )}
          {category === "events" && (
            <CheckboxInput
              label="Webinar"
              name="isWebinar"
              value={data.meta.isWebinar}
            />
          )}
          {category === "events" && (
            <TextInput label="Adres" name="address" value={data.meta.address} />
          )}
          {category === "events" && (
            <TextInput
              label="WP Forms ID"
              name="wordpressFormId"
              value={data.meta.wordpressFormId}
            />
          )}
          <TextInput
            label="Priorytet"
            name="priority"
            type="number"
            value={data.priority || 0}
          />
          <TextInput
            label="Kolor tła (np. #FFFFFF)"
            name="backgroundColor"
            value={data.meta.backgroundColor}
          />
          {category === "movies" ? (
            <TextInput
              label="YouTube ID"
              name="youtubeId"
              value={data.meta.youtubeId}
            />
          ) : (
            <FileInput label="Obrazek" name="image" value={data.meta.image} />
          )}
          <TexteditorInput label="Treść" name="content" value={data.content} />
          {category === "events" && (
            <TextareaInput
              label="Powitalna wiadomość email do uczestnika (HTML)"
              name="participantJoinedEmail"
              value={data.meta.participantJoinedEmail}
              description={`Wstaw {QR_CODE} dla kodu uczestnika, {QR_CODE_URL} dla adresu z kodem QR uczestnika, <img src="{QR_CODE_URL}?file"> dla obrazka kodu QR, {WEBINAR_URL} dla adresu URL webinarium, <a href="{WEBINAR_URL}">Otwórz webinar</a> dla linka do webinarium`}
              collapsable
            />
          )}
          {category === "events" && (
            <TextInput
              label="Tytuł przypomnienia email"
              name="participantNotifyEmailSubject"
              value={data.meta.participantNotifyEmailSubject}
              placeholder="Przypomnienie ARCH_"
              collapsable
            />
          )}
          {category === "events" && (
            <TextareaInput
              label="Przypomnienie email (HTML)"
              name="participantNotifyEmail"
              value={data.meta.participantNotifyEmail}
              description={`Wstaw {QR_CODE} dla kodu uczestnika, {QR_CODE_URL} dla adresu z kodem QR uczestnika, <img src="{QR_CODE_URL}?file"> dla obrazka kodu QR, {WEBINAR_URL} dla adresu URL webinarium`}
              collapsable
            />
          )}
          {category === "events" && (
            <TextareaInput
              label="Przypomnienie SMS (HTML)"
              name="participantNotifySms"
              value={data.meta.participantNotifySms}
              description={`Wstaw {QR_CODE} dla kodu uczestnika, {QR_CODE_URL} dla adresu z kodem QR uczestnika, {WEBINAR_URL} dla adresu URL webinarium; ${
                smsStats.length
              } znaków, ${this.formatSmsMessagesAmount(
                smsStats.messagesAmount
              )}`}
              onChange={this.handleParticipantNotifySmsChange}
              collapsable
            />
          )}
          {category === "events" && (
            <TextInput
              label="ID Webinarium ClickMeeting.com"
              name="webinarId"
              value={data.meta.webinarId}
              collapsable
            />
          )}
          {category === "events" && (
            <TextInput
              label="ID premierowego filmu YouTube"
              name="youTubePremiere"
              value={data.meta.youTubePremiere}
              collapsable
            />
          )}
          {category === "events" && (
            <TexteditorInput
              label="Opis premierowego filmu YouTube"
              name="youTubePremiereContent"
              value={data.meta.youTubePremiereContent}
              collapsable
            />
          )}
          {category === "events" && (
            <TextInput
              label="Tytuł przypomnienia email 30 minut przed webinarium"
              name="participantWebinarNotifyEmailSubject"
              value={data.meta.participantWebinarNotifyEmailSubject}
              placeholder="Przypomnienie ARCH_"
              collapsable
            />
          )}
          {category === "events" && (
            <TextareaInput
              label="Przypomnienie email (HTML) 30 minut przed webinarium"
              name="participantWebinarNotifyEmail"
              value={data.meta.participantWebinarNotifyEmail}
              description={`Wstaw {WEBINAR_URL} dla adresu URL webinarium, <a href="{WEBINAR_URL}">Otwórz webinar</a> dla linka do webinarium`}
              collapsable
            />
          )}
          {category === "events" && (
            <ContestForm name="contest" value={data.meta.contest} />
          )}
          {category === "events" && (
            <TextInput
              label="Dodatkowe pytanie"
              name="additionalEventQuestion"
              value={data.meta.additionalEventQuestion}
              placeholder=""
              collapsable
            />
          )}
        </Form>
        {error ? <span className="result fail">{error}</span> : null}
      </div>
    );
  }
}

export default ArticleForm;
