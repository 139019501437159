import React, { Component } from 'react';
import querystring from 'querystring'
import Login from '../Login'
import Nav from '../Nav'
import ArticlesPage from '../ArticlesPage'
import CompaniesPage from '../CompaniesPage'
import CompanyCategoriesPage from '../CompanyCategoriesPage'
import ProductCategoriesPage from '../ProductCategoriesPage'
import MessagePage from '../MessagePage'
import AnnouncementsPage from '../AnnouncementsPage'
import UsersPage from '../UsersPage'
import StaticPage from '../StaticPage'
import HomePage from '../HomePage'
import './App.css';

class App extends Component {
  constructor(props) {
    super(props)
    this.state = { logged: false, page: '' }
    this.handleLogin = this.handleLogin.bind(this)
    this.handleChangePage = this.handleChangePage.bind(this)
  }
  handleLogin() {
    this.setState({ logged: true })
  }
  handleChangePage(page) {
    this.setState({ page: '' }, () => this.setState({ page }))
  }
  render() {
    const { logged, page } = this.state
    const [pageName, pageOptions] = page.split('?')
    if (!logged) return <Login onLogin={this.handleLogin} />
    const query = querystring.parse(pageOptions)

    return (
      <div className="App">
        <Nav onChangePage={this.handleChangePage} />
        <div className="content">
          {pageName === 'articles' ? <ArticlesPage {...query} /> : null}
          {pageName === 'companies' ? <CompaniesPage {...query} /> : null}
          {pageName === 'companyCategories' ? <CompanyCategoriesPage {...query} /> : null}
          {pageName === 'productCategories' ? <ProductCategoriesPage {...query} /> : null}
          {pageName === 'message' ? <MessagePage {...query} /> : null}
          {pageName === 'announcements' ? <AnnouncementsPage {...query} /> : null}
          {pageName === 'users' ? <UsersPage {...query} /> : null}
          {pageName === 'static' ? <StaticPage {...query} /> : null}
          {pageName === 'home' ? <HomePage {...query} /> : null}
        </div>
      </div>
    );
  }
}

export default App;
