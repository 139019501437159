import React, { Component } from 'react'
import moment from 'moment'
import { server, authorization } from '../config'
import './UsersList.css'

class UsersList extends Component {
  constructor(props) {
    super(props)
    this.state = { users: [] }
  }
  componentDidMount() {
    this.loadUsers()
  }
  async loadUsers() {
    const resp = await fetch(`${server}/users`, { headers: { authorization } })
    this.setState({ users: await resp.json() })
  }
  async handleDelete(user) {
    const sure = window.confirm(`Czy na pewno chcesz usunąć uytkownika ${user.email}?`)
    if (sure) {
      await fetch(`${server}/user/${user.id}`, {
        method: 'DELETE',
        headers: { authorization },
      })
      await this.loadUsers()
    }
  }
  handleEdit(user) {
    this.props.onEditUser(user.id)
  }
  render() {
    const { users } = this.state
    return (
      <div className="UsersList">
        <table>
          <thead>
            <tr>
              <th>Email</th>
              <th>Imię i nazwisko</th>
              <th>Data ostatniej aktywności</th>
              <th>Data dołączenia</th>
            </tr>
          </thead>
          <tbody>
            {users.map(user => [<tr key={user.id}>
              <td>{user.email}</td>
              <td>{user.details.name}</td>
              <td>{user.lastActivityTimestamp && moment(user.lastActivityTimestamp).calendar()}</td>
              <td>{moment(user.createdAt).calendar()}</td>
            </tr>, <tr key={`${user.id}-details`}>
              <td colSpan={5}>
                <nav>
                  <button onClick={this.handleEdit.bind(this, user)}>Edytuj</button>
                  <button onClick={this.handleDelete.bind(this, user)}>Usuń</button>
                </nav>
                <dl>
                  {user.provinces.length > 0 && <dt>Województwa:</dt>}
                  {user.provinces.length > 0 && <dd>{user.provinces.join()}</dd>}
                  {user.loginUrl && <dt>Link aktywacyjny:</dt>}
                  {user.loginUrl && <dd>{user.loginUrl}</dd>}
                  {user.admin && <dt>Administrator:</dt>}
                  {user.admin && <dd>Tak</dd>}
                </dl>
              </td>
            </tr>])}
          </tbody>
        </table>
      </div>
    );
  }
}

export default UsersList;
