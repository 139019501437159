import React, { Component } from "react";
import moment from "moment";

const timestampToInputValue = ts =>
  typeof ts === "string" ? ts.replace(/Z$/, "") : ts;

class FormTextInput extends Component {
  constructor(props) {
    super(props);
    this.state = { value: this.parsePropsValue(props.value) };
    this.handleChange = this.handleChange.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.value !== nextProps.value) {
      this.setState({ value: this.parsePropsValue(nextProps.value) });
    }
  }
  parsePropsValue(value) {
    const { type } = this.props;
    if (type === "datetime") {
      return moment.utc(value).toISOString() || "";
    }
    if (type === "datetime-local") {
      return (
        moment(value)
          .add(moment(value).utcOffset(), "minutes")
          .toISOString() || ""
      );
    }
    return value || "";
  }
  getValue() {
    const { type } = this.props;
    const { value } = this.state;
    if (type === "datetime") {
      return moment.utc(value).toISOString();
    }
    if (type === "datetime-local") {
      return moment
        .utc(value)
        .subtract(moment(value).utcOffset(), "minutes")
        .toISOString();
    }
    return value;
  }
  handleChange(event) {
    const { value } = event.target;
    this.setState({ value });
  }
  render() {
    const { input } = this;
    const {
      label,
      name,
      type,
      disabled,
      placeholder,
      collapsable
    } = this.props;
    let { value } = this.state;
    let inputType = type || "text";
    if (inputType === "datetime") inputType = "datetime-local";
    if (inputType.startsWith("datetime")) value = timestampToInputValue(value);

    const header = <span>{label}</span>;
    const content = (
      <input
        type={inputType}
        name={name}
        placeholder={placeholder}
        onChange={this.handleChange}
        value={value}
        disabled={disabled}
      />
    );

    if (collapsable) {
      return (
        <label>
          <details>
            <summary>{header}</summary>
            {content}
          </details>
        </label>
      );
    }

    return (
      <label>
        {header}
        {content}
      </label>
    );
  }
}

export default FormTextInput;
