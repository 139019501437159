import React, { Component } from 'react';
import Form, { FileInput } from '../Form'
import { server, authorization } from '../config'

class StaticPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: {},
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.updateData()
  }
  async updateData() {
    const resp = await fetch(`${server}/static`)
    this.setState({ data: await resp.json() })
  }
  async handleSubmit(inputs) {
    const tasks = Object.entries(inputs)
      .filter(([key, image]) => typeof image === 'string')
      .map(async ([key, image]) => {
        await fetch(`${server}/static/${key}`, {
          method: 'put',
          headers: { 'Content-type': 'application/json', authorization },
          body: JSON.stringify({
            image
          }),
        })
      })

    await Promise.all(tasks)
  }
  render() {
    let { data } = this.state

    data = Object.assign({
      findCompanies: {},
      findProducts: {},
      pdfs: {},
      sales: {},
      updates: {},
    }, data)

    return (
      <div className="StaticPage">
        <Form onSubmit={this.handleSubmit}>
          <FileInput label="Tło Znajdź firmę" name="findCompanies" value={data.findCompanies.image} />
          <FileInput label="Tło Znajdź produkt" name="findProducts" value={data.findProducts.image} />
          <FileInput label="Tło Przeglądaj Katalogi Firm" name="pdfs" value={data.pdfs.image} />
          <FileInput label="Tło Promocje" name="sales" value={data.sales.image} />
          <FileInput label="Tło Nowości" name="updates" value={data.updates.image} />
        </Form>
      </div>
    );
  }
}

export default StaticPage;
