import React, { Component } from 'react';
import ProductCategoriesList from '../ProductCategoriesList'
import ProductCategoryForm from '../ProductCategoryForm'

class ProductCategoriesPage extends Component {
  constructor(props) {
    super(props)
    this.state = { page: 'list', query: {} }
    this.handleClose = this.handleClose.bind(this)
    this.handleEdit = this.handleEdit.bind(this)
  }
  changePage(page, query = {}) {
    this.setState({ page, query })
  }
  handleClose() {
    this.changePage('list')
  }
  handleEdit(id) {
    this.changePage('edit', { id })
  }
  render() {
    const { page, query } = this.state
    return (
      <div className="ProductCategoriesPage">
        <h1>Kategorie produktów</h1>
        <button onClick={() => this.changePage('new')}>Nowa kategoria</button>
        {page === 'list' && <ProductCategoriesList {...query} onEdit={this.handleEdit} />}
        {page === 'new' && <ProductCategoryForm onClose={this.handleClose} />}
        {page === 'edit' && <ProductCategoryForm {...query} onClose={this.handleClose} />}
      </div>
    );
  }
}

export default ProductCategoriesPage;
