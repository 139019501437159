import React, { Component } from 'react';
import './Form.css'

class Form extends Component {
  constructor(props) {
    super(props)
    this.state = { waiting: false }
    this.childrenRefs = props.children.map(() => React.createRef())
    this.handleSubmit = this.handleSubmit.bind(this)
    this.mounted = true
  }
  componentWillUnmount() {
    this.mounted = false
  }
  getData() {
    const data = {}
    this.childrenRefs.forEach(({ current }) => {
      if (current) {
        data[current.props.name] = current.getValue()
      }
    })
    return data
  }
  async handleSubmit(event) {
    event.preventDefault()
    this.setState({ waiting: true })
    await this.props.onSubmit(this.getData())
    if (this.mounted) this.setState({ waiting: false })
  }
  render() {
    const { childrenRefs } = this
    const { waiting } = this.state
    return (
      <div className="Form">
        <form onSubmit={this.handleSubmit} noValidate>
          {this.props.children.filter(child => !!child).map((child, key)  => React.cloneElement(child, { key, ref: childrenRefs[key] }))}
          <input type="submit" value="Wyślij" disabled={waiting} />
        </form>
      </div>
    );
  }
}

export default Form;
