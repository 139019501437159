import React, { Component } from 'react'
import { SelectInput } from '../Form'
import { server, authorization } from '../config'

const loadArticles = async (category) => {
  const resp = await fetch(`${server}/articles/${category}`, { headers: { authorization } })
  return await resp.json()
}

const loadCompanies = async () => {
  const resp = await fetch(`${server}/companies`, { headers: { authorization } })
  const json = await resp.json()
  return json
}

const loadProducts = async () => {
  const resp = await fetch(`${server}/product/categories`, { headers: { authorization } })
  const json = await resp.json()
  return json
}

let pages = { events: [], news: [], sales: [], updates: [], videos: [], movies: [], companies: [], products: [] }
let pagesTimestamp = 0
let loadPagesPromise = null

const loadPages = async () => {
  if (Date.now() - pagesTimestamp < 5000) {
    return pages
  }
  const loadPagesJob = async () => {
    const [events, news, sales, updates, videos, movies, companies, products] = await Promise.all([
      loadArticles('events'),
      loadArticles('news'),
      loadArticles('sales'),
      loadArticles('updates'),
      loadArticles('videos'),
      loadArticles('movies'),
      loadCompanies(),
      loadProducts(),
    ])
    pages = { events, news, sales, updates, videos, movies, companies, products }
    pagesTimestamp = Date.now()
    loadPagesPromise = null
    return pages
  }
  if (!loadPagesPromise) {
    loadPagesPromise = loadPagesJob()
  }
  return loadPagesPromise
}

class PagesSelect extends Component {
  constructor(props) {
    super(props)
    this.select = React.createRef()
    this.state = {
      pages: { events: [], news: [], sales: [], updates: [], videos: [], movies: [], companies: [], products: [] }
    }
  }
  componentDidMount() {
    this.loadPages()
  }
  async loadPages() {
    this.setState({ pages: await loadPages() })
  }
  getValue() {
    return this.select.current.getValue()
  }
  renderPages() {
    const renderProductCategories = (categories, prefix = '') =>
      categories.map(category => [
      <option key={category.id} value={`productCategory-${category.id}`}>{prefix}{category.name}</option>,
        ...renderProductCategories(category.categories, `${prefix}- `)
      ])

    const { includeHomePage, includeMainPages } = this.props
    const { pages } = this.state
    const events = pages.events
      .map(item => <option key={item.id} value={`event-${item.id}`}>{item.meta.internalTitle || item.meta.title}</option>)
    const news = pages.news
      .map(item => <option key={item.id} value={`news-${item.id}`}>{item.meta.internalTitle || item.meta.title}</option>)
    const sales = pages.sales
      .map(item => <option key={item.id} value={`sale-${item.id}`}>{item.meta.internalTitle || item.meta.title}</option>)
    const updates = pages.updates
      .map(item => <option key={item.id} value={`update-${item.id}`}>{item.meta.internalTitle || item.meta.title}</option>)
    const videos = pages.videos
      .map(item => <option key={item.id} value={`video-${item.id}`}>{item.meta.internalTitle || item.meta.title}</option>)
    const movies = pages.movies
      .map(item => <option key={item.id} value={`movie-${item.id}`}>{item.meta.internalTitle || item.meta.title}</option>)
    const companyCategories = pages.companies
      .map(({ category }, index) =>
        <option key={index} value={`companyCategory-${category}`}>{category}</option>)
    const companies = pages.companies
      .map(({ category, companies }, index) =>
        <optgroup key={index} label={`Firmy: ${category}`}>
          {companies.map(item =>
            <option key={item.id} value={`company-${item.id}`}>{item.meta.title}</option>)}
        </optgroup>
      )
    const products = [
      <option key={0} value='productCategory'>Znajdź Produkt</option>,
      renderProductCategories(pages.products, '- ')
    ]
    return [
      <optgroup key="pages" label="Strony">
        {includeHomePage && <option key="none" value="">Ekran główny</option>}
        {includeMainPages &&
          <React.Fragment>
            <option key="events-option" value="events">Wydarzenia</option>
            <option key="news-option" value="newsList">Projekty</option>
            <option key="sales-option" value="sales">Okazje</option>
            <option key="updates-option" value="updates">Nowości</option>
            <option key="companyCategories-option" value="companyCategories">Kategorie firm</option>
            <option key="products-option" value="productCategories">Produkty</option>
            <option key="videos-option" value="videos">Wideo</option>
            <option key="notifications-option" value="notifications">Powiadomienia</option>
          </React.Fragment>}
      </optgroup>,
      <optgroup key="events" label="Wydarzenia">{events}</optgroup>,
      <optgroup key="news" label="Projekty">{news}</optgroup>,
      <optgroup key="sales" label="Okazje">{sales}</optgroup>,
      <optgroup key="updates" label="Nowości">{updates}</optgroup>,
      <optgroup key="videos" label="Artykuły Wideo">{videos}</optgroup>,
      <optgroup key="movies" label="Wideo">{movies}</optgroup>,
      <optgroup key="company-categories" label="Kategorie firm">{companyCategories}</optgroup>,
      companies,
      <optgroup key="products" label="Produkty">{products}</optgroup>,
    ].filter(Boolean)
  }
  render() {
    const { select } = this
    const { label, name, value, options, onChange } = this.props

    return <SelectInput
      ref={select}
      label={label}
      name={name}
      value={value}
      onChange={onChange}
    >
      {options || this.renderPages()}
    </SelectInput>
  }
}

export default PagesSelect
