import React, { Component } from "react";
import { parse } from "not-so-smart-xhtml-parser";
import classNames from "classnames";
import PagesSelect from "../PagesSelect";

const TAGS_TO_UPLOAD = new Set(["image", "file"]);
const TAGS_WITH_CLOSE = new Set(["row"])

class FormTexteditorInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.stringify(props.value || []),
      accepts: false
    };
    this.files = {};
    this.textarea = React.createRef();
    this.handleChange = this.handleChange.bind(this);
    this.handleDragEnter = this.handleDragEnter.bind(this);
    this.handleDragLeave = this.handleDragLeave.bind(this);
    this.handleDragOver = this.handleDragOver.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
    this.addPageLink = this.addPageLink.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    this.setState({ value: this.stringify(nextProps.value || []) });
  }
  handleChange(event) {
    this.setState({ value: event.target.value });
  }
  stringify(tokens) {
    let result = "";
    tokens.forEach(token => {
      if (token.type === "text") {
        result += `${token.text}\n`;
      } else {
        result += `<${token.type}`;
        if (TAGS_TO_UPLOAD.has(token.type)) {
          const id = Object.keys(this.files).length;
          this.files[id] = { [token.type]: token[token.type] };
          token.id = id;
        }
        Object.keys(token).forEach(key => {
          if (key === "type") return;
          if (TAGS_TO_UPLOAD.has(token.type) && key === token.type) return;
          if (TAGS_WITH_CLOSE.has(token.type) && key === "content") return;
          result += ` ${key}="${token[key]}"`;
        });
        result += ">\n";
        
        if (TAGS_WITH_CLOSE.has(token.type)) {
          result += this.stringify(token.content)
          result += `</${token.type}>`
        }
      }
    });
    return result;
  }
  getValue() {
    const result = [];
    const { files } = this;
    const { value } = this.textarea.current;
    let lastTag;
    let content = result
    const prevContents = []

    parse(value, {
      opentag(type) {
        lastTag = { type };
        content.push(lastTag);

        if (TAGS_WITH_CLOSE.has(type)) {
          lastTag.content = [];
          prevContents.push(content);
          content = lastTag.content;
        }
      },
      closetag(type) {
        if (TAGS_WITH_CLOSE.has(type)) {
          content = prevContents.pop()
        }
      },
      attribute(name, value) {
        if (TAGS_TO_UPLOAD.has(lastTag.type) && name === "id") {
          Object.assign(lastTag, files[value]);
        } else {
          lastTag[name] = value;
        }
      },
      text(text) {
        if (text === "\n") return;
        let trimText = text;
        if (text.startsWith("\n")) trimText = trimText.slice(1);
        if (text.endsWith("\n")) trimText = trimText.slice(0, -1);
        content.push({ type: "text", text: trimText });
      }
    });

    return result;
  }
  handleDragEnter() {
    this.setState({ accepts: true });
  }
  handleDragLeave() {
    this.setState({ accepts: false });
  }
  handleDragOver(event) {
    event.stopPropagation();
    event.preventDefault();
    event.dataTransfer.dropEffect = "copy";
  }
  getDataUri(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.addEventListener("error", reject);
      reader.addEventListener("load", () => {
        resolve(reader.result);
      });
      reader.readAsDataURL(file);
    });
  }
  async handleDrop(event) {
    event.stopPropagation();
    event.preventDefault();
    this.setState({ accepts: false });
    const textarea = this.textarea.current;
    let value = textarea.value;
    for (const file of event.dataTransfer.files) {
      const id = Object.keys(this.files).length;
      if (value && !value.endsWith("\n")) value += "\n";
      if (file.type.startsWith("image/")) {
        const data = await this.getDataUri(file);
        this.files[id] = { data };
        value += `<image name="${file.name}" id="${id}">\n`;
      }
      if (file.type === "application/pdf") {
        const data = await this.getDataUri(file);
        this.files[id] = { data };
        value += `<file kind="pdf" name="${file.name}" id="${id}">\n`;
      }
    }
    this.setState({ value });
  }
  addPageLink(href) {
    let { value } = this.state;
    value += `\n<page href="${href}">`;
    this.setState({ value });
  }
  render() {
    const { textarea } = this;
    const { label, name, collapsable } = this.props;
    const { accepts, value } = this.state;

    const header = <span>{label}</span>;

    const content = (
      <>
        <textarea
          ref={textarea}
          className={classNames({ accepts })}
          name={name}
          value={value}
          onChange={this.handleChange}
          onDragEnter={this.handleDragEnter}
          onDragLeave={this.handleDragLeave}
          onDragOver={this.handleDragOver}
          onDrop={this.handleDrop}
        />
        <div className="legend">
          <b>Tagi specjalne</b>
          <ul>
            <li>
              <code>{'<heading level="1" text="Adres">'}</code>
            </li>
            <li>
              <code>{'<image name="" id="0">'}</code> upuść obrazek aby dodać
              nowy
            </li>
            <li>
              <code>
                {'<image name="" id="0" link="https://archevent.pl">'}
              </code>{" "}
              upuść obrazek aby dodać nowy i wpisz paramter "link" aby obrazek
              otworzył podaną stronę
            </li>
            <li>
              <code>{'<image name="" id="0" file-link="catalog.pdf">'}</code>{" "}
              upuść obrazek aby dodać nowy i wpisz parametr "file-link" aby
              obrazek otworzył dodany wcześniej dokument z podaną nazwą
            </li>
            <li>
              <code>{'<text-with-bg text-color="black" bg-color="green" text="Lorem ipsum">'}</code>
            </li>
            <li>
              <code>{'<file type="pdf" name="" id="0" visible="true">'}</code>{" "}
              upuść plik aby dodać nowy
            </li>
            <li>
              <code>{'<address search="ul. Pułaskiego 10/22, Kraków">'}</code>
            </li>
            <li>
              <code>{'<tel number="+48 555 555 555">'}</code>
            </li>
            <li>
              <code>{'<email address="contact@rocketapp.studio">'}</code>
            </li>
            <li>
              <code>
                {'<details text="tekst po kliknięciu na czytaj więcej">'}
              </code>
            </li>
            <li>
              <code>
                {
                  '<link text="Odwiedź ARCHevent.pl" href="https://archevent.pl">'
                }
              </code>
            </li>
            <li>
              <code>{'<page href="event-3">'}</code> Wybierz aby dodać:{" "}
              <PagesSelect onChange={this.addPageLink} />
            </li>
            <li>
              <code>{'<youtube id="7J52mDjZzto">'}</code>
            </li>
            <li>
              <code>{'<row>inne elementy</row>'}</code> pokazuje wewnętrzne elementy obok siebie
            </li>
          </ul>
        </div>
      </>
    );

    if (collapsable) {
      return (
        <label>
          <details>
            <summary>{header}</summary>
            {content}
          </details>
        </label>
      );
    }

    return (
      <label>
        {header}
        {content}
      </label>
    );
  }
}

export default FormTexteditorInput;
