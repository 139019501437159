import React, { Component } from 'react';
import './Login.css';
import { server, setAuthorization } from '../config'

class Login extends Component {
  constructor(props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this)
  }
  async componentDidMount() {
    await this.login(sessionStorage.token)
  }
  async login(token) {
    const authorization = `Basic ${token}`
    const resp = await fetch(`${server}/auth`, { headers: { authorization } })
    if (!resp.ok) return false
    setAuthorization(authorization)
    sessionStorage.token = token
    this.props.onLogin()
    return true
  }
  async handleSubmit(event) {
    event.preventDefault()
    const [token] = Array.from(event.target.elements)
      .filter(input => input.name === 'token')
      .map(input => input.value)
    await this.login(token)
  }
  render() {
    return (
      <div className="Login">
        <form onSubmit={this.handleSubmit}>
          <label htmlFor="token">Token autoryzacyjny</label>
          <input type="password" name="token" id="token" />
          <input type="submit" value="Zaloguj" />
        </form>
      </div>
    );
  }
}

export default Login;
