import React, { Component, createRef, Fragment } from 'react'
import Form, { TextInput, MultiSelectInput, DatalistInput, SelectInput } from '../Form'
import PagesSelect from '../PagesSelect'
import { server, authorization } from '../config'
// import './AnnouncementsPage.css'

const defaultData = {}

class AnnouncementsPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      result: '',
      data: defaultData,
      lastAnnouncement: {}
    }
    this.formRef = createRef()
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.loadLastAnnouncement = this.loadLastAnnouncement.bind(this)
  }
  componentDidMount() {
    this.loadLastAnnouncement()
  }
  async loadLastAnnouncement() {
    const resp = await fetch(`${server}/announcement`)
    this.setState({ lastAnnouncement: await resp.json() })
  }
  async handleSubmit(data) {
    this.setState({ data })
    if (!data.title || !data.body) return
    const resp = await fetch(`${server}/announcement`, {
      method: 'post',
      headers: { 'Content-type': 'application/json', authorization },
      body: JSON.stringify({
        ...data,
      }),
    })
    this.setState({
      result: resp.ok ? 'ok' : 'fail',
      data,
    })
    this.loadLastAnnouncement()
  }
  async handleDelete() {
    const resp = await fetch(`${server}/announcement`, {
      method: 'post',
      headers: { 'Content-type': 'application/json', authorization },
      body: JSON.stringify({}),
    })
    this.setState({
      result: resp.ok ? 'ok' : 'fail',
    })
    this.loadLastAnnouncement()
  }
  getData() {
    const data = { ...defaultData, ...this.state.data }
    const formData = this.formRef.current.getData()
    Object.entries(formData).forEach(([key, value]) => {
      if (value != null) {
        data[key] = value
      }
    })
    return data
  }
  render() {
    const { result, data, lastAnnouncement } = this.state
    return (
      <div className="AnnouncementsPage">
        <h1>Nowy komunikat</h1>
        {result === 'ok' ? <span className="result ok">Komunikat został zmieniony</span> : null}
        {result === 'fail' ? <span className="result fail">Wystąpił błąd przy próbie wysłania komunikatu</span> : null}
        <p>Aktualny komunikat: {lastAnnouncement.title || "Brak"} {lastAnnouncement.title && <button onClick={this.handleDelete}>Usuń</button>}</p>
        <Form onSubmit={this.handleSubmit} ref={this.formRef}>
          <TextInput label="Tytuł" name="title" value={data.title} />
          <TextInput label="Treść" name="body" value={data.body} />
          <PagesSelect label="Przekierowanie" name="redirect" value={data.redirect} includeHomePage includeMainPages />
        </Form>
      </div>
    );
  }
}

export default AnnouncementsPage;
