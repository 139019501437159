import React, { Component } from 'react';
import Form, { TextInput, FileInput, MultiSelectInput, SelectInput } from '../Form'
import { server, authorization } from '../config'
import './CompanyProducts.css'

class CompanyProducts extends Component {
  constructor(props) {
    super(props)
    this.state = { products: [], categories: [], pendingProducts: [] }
    this.handleSubmit = this.handleSubmit.bind(this)
  }
  componentDidMount() {
    this.loadProducts()
    this.getCategories()
  }
  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      this.loadProducts()
    }
  }
  async loadProducts() {
    const { id } = this.props
    this.setState({ products: [] })
    const url = `${server}/company/${id}/products`
    const resp = await fetch(url, { headers: { authorization } })
    this.setState({ products: await resp.json() })
  }
  async getCategories() {
    const resp = await fetch(`${server}/product/categories`, {
      headers: { authorization },
    })
    const categories = await resp.json()
    this.setState({ categories })
  }
  getCategoriesOptions(categories = this.state.categories, prefix = '') {
    return categories
      .map(({ id, name, categories }) => [
        { value: id, label: `${id}. ${prefix + name}` },
        ...this.getCategoriesOptions(categories, `${prefix}- `)
      ]).flat()
  }
  async handleSubmit({ url, name, categories, image, keywords, companyLabel, order }, productId) {
    const { id } = this.props
    keywords = String(keywords).split(',').map(str => str.trim())
    const product = { categories, details: { url, name, image, keywords, companyLabel, order } }
    this.setState({
      pendingProducts: [
        ...this.state.pendingProducts.filter(({ details }) => details.url !== url),
        product
      ]
    })
    const resp = await fetch(`${server}/company/${id}/product/${productId || ''}`, {
      method: productId ? 'put' : 'post',
      headers: { 'Content-type': 'application/json', authorization },
      body: JSON.stringify(product),
    })
    if (resp.ok) {
      await this.loadProducts()
      this.setState({ pendingProducts: this.state.pendingProducts.filter(a => a !== product) })
    } else {
      product.error = 'Wystąpił błąd'
      this.setState({ pendingProducts: [...this.state.pendingProducts] })
    }
  }
  async deleteProduct(productId) {
    if (!window.confirm('Czy jesteś pewien?')) return
    const { id } = this.props
    const resp = await fetch(`${server}/company/${id}/product/${productId}`, {
      method: 'delete',
      headers: { authorization },
    })
    if (resp.ok) await this.loadProducts()
  }
  async handleDelete(event, productId) {
    event.stopPropagation()
    await this.deleteProduct(productId)
  }
  renderForm(data) {
    const categories = this.getCategoriesOptions()
    let { keywords } = data.details
    keywords = Array.isArray(keywords) ? keywords.join() : ''

    return (
      <Form onSubmit={(newData) => this.handleSubmit(newData, data.id)}>
        {data.id != null ? <TextInput label="ID" name="id" value={data.id} disabled /> : null}
        <TextInput label="Adres URL" name="url" value={data.details.url} />
        <TextInput label="Nazwa" name="name" value={data.details.name} />
        <TextInput label="Słowa kluczowe" name="keywords" value={keywords} />
        {data.clicksCount != null ? <TextInput label="Licznik kliknięć" name="id" value={data.clicksCount} disabled /> : null}
        <FileInput label="Obrazek" name="image" value={data.details.image} />
        <TextInput label="Kolejność" type="number" name="order" value={data.details.order} />
        <MultiSelectInput label="Kategorie" name="categories" options={categories} values={data.categories} />
        <SelectInput label="Adnotacja firmy" name="companyLabel" value={data.details.companyLabel}>
          <option value="companyTransparentImage">Logo firmy</option>
          <option value="companyName">Nazwa firmy</option>
          <option value="none">Brak</option>
        </SelectInput>
      </Form>
    )
  }
  render() {
    const { products, pendingProducts } = this.state

    return (
      <div className="CompanyProducts">
        <ul className="products">
          {products.map((data) => <li key={data.id}>
            <div className="product">
              {this.renderForm(data)}
              <button onClick={(event) => this.handleDelete(event, data.id)}>Usuń</button>
            </div>
          </li>)}
        </ul>

        {pendingProducts.length > 0 ? <React.Fragment>
          <h3>Przetwarzane produkty</h3>
          <ul className="pendingProducts">
            {pendingProducts.map(({ details: { url, image }, error }) => <li key={url} className="product">
              <p className="url">{url}</p>
              <img src={image} height="100" />
              {error ? <p className="error">{error}</p> : null}
            </li>)}
          </ul>
        </React.Fragment> : null}

        <h3>Dodaj produkt</h3>
        <div className="form">
          {this.renderForm({ details: {} })}
        </div>
      </div>
    );
  }
}

export default CompanyProducts;
