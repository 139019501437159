import React, { Component } from 'react';

class FormDatalistInput extends Component {
  constructor(props) {
    super(props)
    this.id = String(Math.random()).slice(2)
    this.state = { value: props.value }
    this.handleChange = this.handleChange.bind(this)
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      value: nextProps.value,
    })
  }
  getValue() {
    return this.state.value
  }
  handleChange(event) {
    const { onChange } = this.props
    const { value } = event.target
    this.setState({ value })
    if (typeof onChange === 'function') {
      onChange(value)
    }
  }
  render() {
    const { label, name, children } = this.props

    return (<label>
      <span>{label}</span>
      <input type="text" list={this.id} name={name} onChange={this.handleChange} />
      <datalist id={this.id}>
        {children}
      </datalist>
    </label>)
  }
}

export default FormDatalistInput;
