import React, { Component } from 'react';
import moment from 'moment'
import 'moment/locale/pl'
import { server, authorization } from '../config'
import './ArticleParticipants.css'

const stringifyMaybeBoolean = (value) => {
  if (value == null) return ''
  return value ? 'TAK' : 'NIE'
}


moment.locale('pl')

const ALL = 'all'
const APPEARED = 'appeared'

class ArticleParticipants extends Component {
  constructor(props) {
    super(props)
    this.state = {
      participants: [],
      mode: ALL,
      search: '',
      modeFilter: () => true,
      searchFilter: () => true,
    }
    this.handleModeChange = this.handleModeChange.bind(this)
    this.handleSearchChange = this.handleSearchChange.bind(this)
    this.handleDeleteButtonClick = this.handleDeleteButtonClick.bind(this)
  }
  componentDidMount() {
    this.loadParticipants()
  }
  parseDate(date) {
    const time = moment(date)
    const now = moment(new Date())
    if (moment.duration(now.diff(time)).asHours() < 20) return time.fromNow()
    return time.format('DD MMMM YYYY r.')
  }
  async loadParticipants() {
    const { id } = this.props
    const resp = await fetch(`${server}/event/${id}/participants`, { headers: { authorization } })
    this.setState({ participants: await resp.json() })
  }
  handleModeChange(event) {
    const mode = event.target.value
    let modeFilter
    if (mode === APPEARED) {
      modeFilter = participant => participant.appeared
    } else {
      modeFilter = () => true
    }
    this.setState({ mode, modeFilter })
  }
  handleSearchChange(event) {
    const { value } = event.target
    const search = value.toLowerCase()
    let searchFilter
    if (search !== '') {
      searchFilter = participant =>
        String(participant.details.name).toLowerCase().includes(search)
        || String(participant.details.company).toLowerCase().includes(search)
        || String(participant.details.tel).toLowerCase().includes(search)
        || String(participant.details.email).toLowerCase().includes(search)
        || String(participant.code).toLowerCase().includes(search)
    } else {
      searchFilter = () => true
    }
    this.setState({ search: value, searchFilter })
  }
  async handleDeleteButtonClick({ id }) {
    // eslint-disable-next-line no-restricted-globals
    if (!confirm('Czy jesteś pewien?')) return
    await fetch(`${server}/event/participate/${id}`, {
      method: 'delete',
      headers: { authorization },
    })
    this.loadParticipants()
  }
  renderAnswers(answers) {
    const { contest } = this.props.meta
    return contest.map((item, index) => {
      const isProper = answers[item.question] === item.answers[item.properAnswer]
      return <div className={`answer ${isProper ? 'proper-answer' : 'invalid-answer'}`} title={`Pytanie: ${item.question}; Odpowiedź: ${answers[item.question]}`} key={index}>{index + 1}</div>
    })
  }
  render() {
    const { participants, mode, modeFilter, searchFilter, search } = this.state
    const showAll = mode === ALL
    const isContest = this.props.meta.contest && this.props.meta.contest.length > 0

    return (
      <div className="ArticleParticipants">
        <header className="filters">
          <label>
            Filtruj:
            <select value={mode} onChange={this.handleModeChange}>
              <option value={ALL}>Wszyscy</option>
              <option value={APPEARED}>Pojawili się</option>
            </select>
          </label>
          <input type="text" placeholder="Szukaj" value={search} onChange={this.handleSearchChange} />
        </header>

        <table>
          <thead>
            <tr>
              <th>Lp.</th>
              <th>Imię i nazwisko</th>
              <th>Email</th>
              <th>Numer telefonu</th>
              <th>Firma</th>
              <th>Źródło</th>
              <th>Zgoda</th>
              <th>Dodatkowa Zgoda</th>
              <th>Kod QR</th>
              {showAll && <th>Pojawił się</th>}
              <th>Data</th>
              {isContest && <th>Odpowiedzi</th>}
              <th></th>
            </tr>
          </thead>
          <tbody>
            {participants.filter(modeFilter).filter(searchFilter).map((participant, index) => <tr key={participant.id}>
              <td>{index + 1}</td>
              <td>{participant.details.name}</td>
              <td>{participant.details.email}</td>
              <td>{participant.details.tel}</td>
              <td>{participant.details.company}</td>
              <td>{participant.userId ? 'app' : 'www'}</td>
              <td>{participant.details.agreement ? 'TAK' : 'NIE'}</td>
              <td>{stringifyMaybeBoolean(participant.details.additionalAgreement)}</td>
              <td><a href={`https://qr.archevent.pl/${participant.code}`} target="new">
                {String(participant.code || '').toUpperCase()}
              </a></td>
              {showAll && <td>{participant.appeared ? 'TAK' : 'NIE'}</td>}
              <td>{this.parseDate(participant.createdAt)}</td>
              {isContest && <td>{this.renderAnswers(participant.details.answers)}</td>}
              <td><button onClick={this.handleDeleteButtonClick.bind(this, participant)}>Usuń</button></td>
            </tr>)}
          </tbody>
        </table>
      </div>
    );
  }
}

export default ArticleParticipants;
