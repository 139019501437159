import React, { Component } from 'react';
import classNames from 'classnames'
import './Nav.css';

const nav = [
  { name: 'articles?category=events', title: 'Wydarzenia' },
  { name: 'articles?category=news', title: 'Projekty' },
  { name: 'articles?category=sales', title: 'Okazje' },
  { name: 'articles?category=updates', title: 'Nowości' },
  { name: 'articles?category=videos', title: 'Artykuły Wideo' },
  { name: 'articles?category=movies', title: 'Wideo' },
  { name: 'productCategories', title: 'Kategorie produktów' },
  { name: 'companyCategories', title: 'Kategorie firm' },
  { name: 'companies', title: 'Firmy' },
  { name: 'message', title: 'Powiadomienie' },
  { name: 'announcements', title: 'Komunikaty' },
  { name: 'users', title: 'Użytkownicy' },
  { name: 'static', title: 'Pliki statyczne' },
  { name: 'home', title: 'Strona główna' },
]

class Nav extends Component {
  constructor(props) {
    super(props)
    this.state = { page: '' }
  }
  componentDidMount() {
    this.changePage(nav[0].name)
  }
  changePage(page) {
    this.setState({ page })
    this.props.onChangePage(page)
  }
  render() {
    const { page } = this.state
    return (
      <nav className="Nav">
        {nav.map((elem, index) => <span
          key={index}
          onClick={() => this.changePage(elem.name)}
          className={classNames({ active: page === elem.name })
        }>{elem.title}</span>)}
      </nav>
    );
  }
}

export default Nav;
